<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>
          <h2 class="brand-text mb-0">
            Użytkownik: {{ business.name }}
          </h2>
        </b-card-title>
      </b-card-header>
    </b-card>
    <b-card>
      <b-card-header>
        <b-card-title>
          <h2 class="brand-text mb-0">
            Dane konta
          </h2>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        Email: {{ business.email }}
        <br>
        Miasto: {{ business.city }}
        <br>
        Numer: {{ business.phone_number }}
        <br>
        Typ konta: {{ business.account_type }}
        <br>
        Kraj: {{ business.country }}
        <br>
        założono: {{ business.created_at }}

        <div>
          <a
            class="btn btn-primary mt-4"
            :href="`sms:${business.phone_number}`"
          >
            Wyślij wiadomość
          </a>
        </div>

      </b-card-body>
    </b-card>

    <b-card>
      <div>
        <h2>
          Notatki
        </h2>
        <div
          v-for="note in notes"
          :key="note"
        >
          <div class="d-flex justify-content-between mt-1">
            <div>
              {{ note.note }}
            </div>

            <div>
              <button
                class="btn btn-danger"
                style="padding: 5px;"
                @click="DeleteNote(note.id)"
              >
                Usuń notatkę
              </button>
            </div>
          </div>
          <hr>
        </div>

        <button
          v-b-modal.modal-1
          class="btn btn-primary"
          variant="primary"
        >
          Dodaj notatkę
        </button>

        <b-modal
          id="modal-1"
          title="Dodaj notatkę"
        >
          <h2>
            Dodaj notatkę
          </h2>
          <b-card-body>
            <form @submit.prevent="AddNote()">
              <input
                v-model="note"
                class="form-control"
              >
            </form>
          </b-card-body>
        </b-modal>
      </div>
    </b-card>

    <b-card>
      <div>
        <h2>
          Komentarze
        </h2>
      </div>

      <hr>

      <div
        v-for="comment in comments"
        :key="comment"
      >
        <h2>
          {{ comment.business.name ? comment.business.name : 'Nowy biznes' }}
        </h2>

        <div v-if="comment.editable">
          <div class="d-flex justify-content-between mt-1">
            <div>
              {{ comment.comment }}
            </div>
            <div>
              <button
                class="btn btn-danger"
                style="padding: 5px;"
                @click="DeleteComment(comment.id)"
              >
                Usuń komentarz
              </button>
            </div>
          </div>
          <hr>
        </div>

        <div v-else>
          <div class="d-flex mt-1">
            <div>
              {{ comment.comment }}
            </div>
          </div>
          <hr>
        </div>

      </div>

      <button
        v-if="CommentsPaginated.next_page_url "
        class="w-100 btn btn-primary"
        style="padding: 5px;"
        @click="ShowMoreComments()"
      >
        Załaduj więcej
      </button>

      <hr>

      <button
        v-b-modal.modal-2
        class="btn btn-primary"
      >
        Dodaj Komentarz
      </button>

      <b-modal
        id="modal-2"
        title="Dodaj notatkę"
      >
        <h2>
          Dodaj Komentarz
        </h2>

        <b-card-body>
          <form @submit.prevent="AddComment()">
            <input
              v-model="comment"
              class="form-control"
            >
          </form>
        </b-card-body>

      </b-modal>
    </b-card>

    <b-card>
      <h2>
        Zamówienia
      </h2>
      <div
        v-for="order in business.orders"
        :key="order"
      >
        <div class="d-flex justify-content-between mt-1">
          <router-link :to="{ name: 'Order-show', params: { id: order.id }}">
            {{ order.status }} | {{ order.created_at }} | {{ order.updated_at }}
          </router-link>
        </div>
        <hr>
      </div>
    </b-card>

    <b-card>
      <router-link to="/Klienci">
        <button
          class="btn btn-primary"
          style="width:100%"
        >
          Powrót
        </button>
      </router-link>
    </b-card>
  </div>
</template>

<script>
import swal from 'sweetalert2'
import axios from 'axios'
import {
  BCard, BCardText, BImg, BButton,
} from 'bootstrap-vue'

export default {
  name: 'OfferAdd',
  components: {
    BCard,
    BCardText,
    BImg,
    BButton,
  },
  data() {
    return {
      offer: {},
      categories: [],
      errors: [],
      business: {},
      note: '',
      notes: [],
      comment: '',
      comments: [],
      CommentsPaginated: [],
      CommentsPage: 1,
    }
  },
  mounted() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}Profile/${this.$route.params.id}`, config)
      .then(response => {
        this.business = response.data.success
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 401) {
          this.$router.push('/error-404')
        }
      })

    this.FetchNotes()
    this.FetchComments()
  },
  methods: {
    AddNote() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      const postdata = {
        note: this.note,
      }

      axios.post(`${process.env.VUE_APP_API_URL}Client/Note/${this.$route.params.id}`, postdata, config)
        .then(response => {
          swal.fire({
            title: 'Udało się!',
            text: 'Notatka została dodana',
            type: 'success',
            icon: 'success',
            confirmButtonText: 'OK',
          })
          this.FetchNotes()
        })
        .catch(error => {
          swal({
            title: 'Błąd!',
            text: 'Nie udało się dodać notatki',
            type: 'error',
          })
        })
    },
    FetchNotes() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.get(`${process.env.VUE_APP_API_URL}Client/Note/${this.$route.params.id}`, config)
        .then(response => {
          this.notes = response.data.notes
        })
        .catch(error => {
          swal.fire({
            title: 'Błąd!',
            text: 'Nie udało się pobrać notatek',
            type: 'error',
          })
        })
    },
    DeleteNote(id) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.delete(`${process.env.VUE_APP_API_URL}Client/Note/${id}`, config)
        .then(response => {
          swal.fire({
            title: 'Udało się!',
            text: 'Notatka została usunięta',
            type: 'success',
            icon: 'success',
            confirmButtonText: 'OK',
          })
          this.FetchNotes()
        })
        .catch(error => {
          swal({
            title: 'Błąd!',
            text: 'Nie udało się usunąć notatki',
            type: 'error',
          })
        })
    },
    AddComment() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      const postdata = {
        comment: this.comment,
      }

      axios.post(`${process.env.VUE_APP_API_URL}Client/${this.$route.params.id}/Comment`, postdata, config)
        .then(response => {
          swal.fire({
            title: 'Udało się!',
            text: 'Komentarz został dodany',
            type: 'success',
            icon: 'success',
            confirmButtonText: 'OK',
          })
          this.FetchComments()
        })
        .catch(error => {
          swal.fire({
            title: 'Błąd!',
            text: 'Nie udało się dodać komentarza',
            type: 'error',
          })
        })
    },
    FetchComments() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.get(`${process.env.VUE_APP_API_URL}Client/${this.$route.params.id}/Comment`, config)
        .then(response => {
          this.comments = response.data.comments.data
          this.CommentsPaginated = response.data.comments
        })
        .catch(error => {
          swal.fire({
            title: 'Błąd!',
            text: 'Nie udało się pobrać komentarzy',
            type: 'error',
          })
        })
    },
    ShowMoreComments() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      this.CommentsPage += 1

      axios.get(`${process.env.VUE_APP_API_URL}Client/${this.$route.params.id}/Comment` + `?page=${this.CommentsPage}`, config)
        .then(response => {
          this.comments = this.comments.concat(response.data.comments.data)
          this.CommentsPaginated = response.data.comments
        })
        .catch(error => {
          swal.fire({
            title: 'Błąd!',
            text: 'Nie udało się pobrać komentarzy',
            type: 'error',
          })
        })
    },
    DeleteComment(commentId) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.delete(`${process.env.VUE_APP_API_URL}Client/${commentId}/Comment`, config)
        .then(response => {
          swal.fire({
            title: 'Udało się!',
            text: 'Komentarz został usunięty',
            type: 'success',
            icon: 'success',
            confirmButtonText: 'OK',
          })
          this.FetchComments()
        })
        .catch(error => {
          swal.fire({
            title: 'Błąd!',
            text: 'Nie udało się usunąć komentarza',
            type: 'error',
          })
        })
    },
  },
}
</script>

<style>

</style>
